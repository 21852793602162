import React from 'react';
import { Container, Typography, Button, Grid} from '@material-ui/core';
import { Link } from 'react-router-dom';

import useStyles from './styles';
import CartItem from './CartItem/CartItem';






const Cart = ({cart, handleUpdateCartQty, handleRemoveFromCart, handleEmptyCart}) => {
  const classes = useStyles();
 
    const EmptyCart = () =>(
        <Typography variant='subtitle1'>You have no items in your shopping cart,
         <Link component={Link} to="/" className={classes.Link}>start adding some</Link>! 
        </Typography>
    );

    const FilledCart = () => (
        <Typography>
            <>
              <Grid container spacing={3}>
                {cart.line_items.map((item) => (
                   <Grid item xs={12} sm={3} key={item.id}>
                     <CartItem item={item} onUpdateCartQty={handleUpdateCartQty} onRemoveFromCart={handleRemoveFromCart}/>
                   </Grid>
                ))}
              </Grid>
              <div className={classes.cartDetails}>
                  <Typography variant='h4'>subtotal: {cart.subtotal.formatted_with_symbol}</Typography>
                  <div>
                      <Button className={classes.emptyButton} size='large' type='button' variant='contained' color="secondary" onClick={handleEmptyCart} >Empty Cart</Button>
                      <Button component={Link} to="/checkout" className={classes.checkoutButton}  size='large' type='button' variant='contained' color='primary' >Checkout</Button>
                  </div>
              </div>
            </>
        </Typography>
    );
    if(!cart.line_items)
    return  '...loading'; 

  return (
    <Container>
        <div className={classes.toolbar}/>
        <Typography className={classes.title} variant='h4' gutterBottom>Your Shopping Cart</Typography>
        {!cart.line_items.length ? <EmptyCart/> : <FilledCart/>}
    </Container>
  )
}

export default Cart
